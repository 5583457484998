.image {
  max-height: 60px;
  border: 0;
  background: none;
  max-width: 60px;
  cursor: pointer;
  padding: 0;
}

.wrapper {
  justify-content: center;
  width: 60px;
}
